import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { UltraMixerLicense } from '../../../login/interfaces/um-keys-user';
import {
  activateDemoAction,
  activateDemoFailedAction,
  activateDemoSucceededAction,
  activateLicenseAction,
  activateLicenseFailedAction,
  activateLicenseSucceededAction,
  createAccountFailedAction,
  createAccountSucceededAction,
  initAccountVerificationAction,
  initAccountVerificationFailedAction,
  initAccountVerificationSucceededAction,
  initLoginScreenSucceededAction,
  signInAction,
  signInFailedAction,
  signInSuccessfulAction,
  uploadUltraMixerLicenceAction,
  uploadUltraMixerLicenceFailedAction,
  uploadUltraMixerLicenceSucceededAction,
} from '../actions/login.actions';
import { createAccountAction } from './../actions/login.actions';

export interface LoginState {
  loggedIn: boolean;
  isRegistrated: boolean;
  loggingIn: boolean;
  activatingLicense: boolean;
  isActivated: boolean;
  isKeySelected: boolean;

  signInError: string;
  signUpError: string;
  keySelectionError: string;
  username: string;
  password: string;
  licenses: UltraMixerLicense[];
  uploadingUltraMixerLicense: boolean;
  creatingAccount: boolean;
  // Verify Account
  verifingAccount: boolean;
  isAccountVerified: boolean;
  verificationError: string;

  os: string;
}

const initialState: LoginState = {
  loggedIn: false,
  isRegistrated: false,
  loggingIn: false,
  isKeySelected: false,
  activatingLicense: false,
  isActivated: false,
  signInError: '',
  signUpError: '',
  keySelectionError: '',
  username: '',
  password: '',
  licenses: ([] = [
    /*
    {
      productName: 'UltraMixer 6 Pro Entertain for Windows',
      purchaseId: 876543,
      keyData: null,
      purchaseDate: new Date().toDateString(),
      activated: false,
    },
    {
      productName: 'UltraMixer 6 Pro Entertain for Windows',
      purchaseId: 8765432,
      keyData: null,
      purchaseDate: new Date().toDateString(),
      activated: false,
    },
    {
      productName: 'UltraMixer 6 Pro Entertain for Windows',
      purchaseId: 8765433,
      keyData: null,
      purchaseDate: new Date().toDateString(),
      activated: false,
    },
    {
      productName: 'UltraMixer 6 Pro Entertain for Windows',
      purchaseId: 8765434,
      keyData: null,
      purchaseDate: new Date().toDateString(),
      activated: false,
    },
    */
  ]),
  uploadingUltraMixerLicense: false,
  creatingAccount: false,
  verifingAccount: false,
  isAccountVerified: false,
  verificationError: null,
  os: null,
};

export const loginReducer = createReducer(
  initialState,
  on(signInAction, (state, action): LoginState => {
    return {
      ...state,
      loggingIn: true,
      signInError: '',
      signUpError: '',
    };
  }),
  on(signInSuccessfulAction, (state, action): LoginState => {
    return {
      ...state,
      loggedIn: true,
      loggingIn: false,
      username: action.email,
      password: action.password,
      licenses: action.licenses,
    };
  }),
  on(signInFailedAction, (state, action): LoginState => {
    return {
      ...state,
      loggedIn: false,
      loggingIn: false,
      signInError: action.signInError.toString(),
    };
  }),

  // Registration actions
  on(createAccountAction, (state, action): LoginState => {
    return {
      ...state,
      creatingAccount: true,
      signUpError: '',
      signInError: '',
    };
  }),
  on(createAccountSucceededAction, (state, action): LoginState => {
    return {
      ...state,
      isRegistrated: true, // todo: über Firebase machen?
      creatingAccount: false,
      username: action.username,
      password: action.password, // TODO: Warum speichern wir das?
    };
  }),
  on(createAccountFailedAction, (state, action): LoginState => {
    return {
      ...state,
      isRegistrated: false,
      creatingAccount: false,
      signUpError: action.signUpError,
    };
  }),
  // Key selection actions
  on(activateDemoAction, (state, action): LoginState => {
    return {
      ...state,
      activatingLicense: true,
    };
  }),
  on(activateDemoSucceededAction, (state, action): LoginState => {
    return {
      ...state,
      activatingLicense: false,
      isActivated: true,
    };
  }),
  on(activateDemoFailedAction, (state, action): LoginState => {
    return {
      ...state,
      activatingLicense: false,
      isActivated: false,
    };
  }),
  // Key selection actions
  on(activateLicenseAction, (state, action): LoginState => {
    return {
      ...state,
      loggingIn: true,
    };
  }),
  on(activateLicenseSucceededAction, (state, action): LoginState => {
    return {
      ...state,
      isKeySelected: true,
      activatingLicense: false,
      //    loggingIn: false,
      isActivated: true,
    };
  }),
  on(activateLicenseFailedAction, (state, action): LoginState => {
    return {
      ...state,
      isKeySelected: false,
      //  loggingIn: false,
      keySelectionError: action.error,
      isActivated: false,
      activatingLicense: false,
    };
  }),

  // Account verification actions
  on(initAccountVerificationAction, (state, action): LoginState => {
    return {
      ...state,
      verifingAccount: true,
      isAccountVerified: false,
    };
  }),
  on(initAccountVerificationSucceededAction, (state, action): LoginState => {
    return {
      ...state,
      verifingAccount: false,
      isAccountVerified: true,
    };
  }),
  on(initAccountVerificationFailedAction, (state, action): LoginState => {
    return {
      ...state,
      verifingAccount: false,
      isAccountVerified: false,
    };
  }),
  on(uploadUltraMixerLicenceAction, (state, action): LoginState => {
    return {
      ...state,
      uploadingUltraMixerLicense: true,
    };
  }),
  on(uploadUltraMixerLicenceSucceededAction, (state, action): LoginState => {
    return {
      ...state,
      uploadingUltraMixerLicense: false,
      licenses: action.licenses,
    };
  }),
  on(uploadUltraMixerLicenceFailedAction, (state, action): LoginState => {
    return {
      ...state,
      uploadingUltraMixerLicense: false,
    };
  }),
  on(initLoginScreenSucceededAction, (state, action): LoginState => {
    return {
      ...state,
      os: action.os,
    };
  })
);

// getters of login
export const loginState = createFeatureSelector<LoginState>('login');
export const isLoggedIn = createSelector(loginState, (state: LoginState) => state.loggedIn);
export const isLoggingIn = createSelector(loginState, (state: LoginState) => state.loggingIn);
export const getSignInError = createSelector(loginState, (state: LoginState) => state.signInError);
export const getSignUpError = createSelector(loginState, (state: LoginState) => state.signUpError);
export const getKeySelectionError = createSelector(loginState, (state: LoginState) => state.keySelectionError);
export const getUsername = createSelector(loginState, (state: LoginState) => state.username);
export const getPassword = createSelector(loginState, (state: LoginState) => state.password);

export const isUploadingUltraMixerLicense = createSelector(loginState, (state: LoginState) => state.uploadingUltraMixerLicense);
export const getLicenses = createSelector(loginState, (state: LoginState) => state.licenses);
export const isCreatingAccount = createSelector(loginState, (state: LoginState) => state.creatingAccount);
export const isActivatingLicense = createSelector(loginState, (state: LoginState) => state.activatingLicense);
export const isActivated = createSelector(loginState, (state: LoginState) => state.isActivated);

// Verifiy Account
export const isAccountVerified = createSelector(loginState, (state: LoginState) => state.isAccountVerified);
export const isVerifingAccount = createSelector(loginState, (state: LoginState) => state.verifingAccount);
export const getVerificationError = createSelector(loginState, (state: LoginState) => state.verificationError);

export const getOS = createSelector(loginState, (state: LoginState) => state.os);
