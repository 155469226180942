import { createAction, props } from '@ngrx/store';
import { UltraMixerLicense } from '../../../login/interfaces/um-keys-user';
import { UmDemoVersion } from '../../../login/interfaces/um-demo-version.interface';

const ACTION_TYPE = '[Login]';

//Actions for login
export const signInAction = createAction(`${ACTION_TYPE} Sign in`, props<{ email: string; password: string }>());
export const signInSuccessfulAction = createAction(
  `${ACTION_TYPE} Sign in successful`,
  props<{ email: string; password: string; licenses: UltraMixerLicense[] }>()
);
export const signInFailedAction = createAction(`${ACTION_TYPE} Sign in failed`, props<{ signInError: string }>());

//Actions for reistration
export const createAccountAction = createAction(
  `${ACTION_TYPE} Creating account`,
  props<{ email: string; password: string; repeatPassword: string }>()
);
export const createAccountSucceededAction = createAction(
  `${ACTION_TYPE} Creating account succeeded`,
  props<{ username: string; password: string }>()
);
export const createAccountFailedAction = createAction(`${ACTION_TYPE} Creating account failed`, props<{ signUpError: string }>());

// Actions for keySelected
export const activateLicenseAction = createAction(`${ACTION_TYPE} Activate license`, props<{ license: UltraMixerLicense }>());
export const activateLicenseSucceededAction = createAction(
  `${ACTION_TYPE} Activate license succeeded`,
  props<{ license: UltraMixerLicense }>()
);
export const activateLicenseFailedAction = createAction(`${ACTION_TYPE} Activate license failed`, props<{ error: string }>());

// Actions for Demo Activation
export const activateDemoAction = createAction(`${ACTION_TYPE} Activate demo license`, props<{ license: UmDemoVersion }>());
export const activateDemoSucceededAction = createAction(
  `${ACTION_TYPE} Activate demo succeeded`,
  props<{ license: UltraMixerLicense | UmDemoVersion }>()
);
export const activateDemoFailedAction = createAction(`${ACTION_TYPE} Activate demo failed`, props<{ error: any }>());

// Actions for accountVerification
export const initAccountVerificationAction = createAction(`${ACTION_TYPE} Init Account Verification`);
export const initAccountVerificationSucceededAction = createAction(`${ACTION_TYPE} Init Account Verification succeeded`);
export const initAccountVerificationFailedAction = createAction(`${ACTION_TYPE} Init Account Verification failed`);

export const triggerLoginContextMenuAction = createAction(`${ACTION_TYPE} Trigger Login Context Menu`, props<{ selectedText: string }>());

export const uploadUltraMixerLicenceAction = createAction(
  `${ACTION_TYPE} Uploading UltraMixer License`,
  props<{ license: UltraMixerLicense }>()
);
export const uploadUltraMixerLicenceSucceededAction = createAction(
  `${ACTION_TYPE} Uploading UltraMixer License succeeded`,
  props<{ licenses: UltraMixerLicense[] }>()
);
export const uploadUltraMixerLicenceFailedAction = createAction(
  `${ACTION_TYPE} Uploading UltraMixer License failed`,
  props<{ error: Error }>()
);

/**
 * Start UltraMixer after successfully activated license or demo version.
 */
export const startUltraMixerAction = createAction(`${ACTION_TYPE} Starting UltraMixer`);

export const initLoginScreenAction = createAction(`${ACTION_TYPE} Inititalizing Login Screen`);
export const initLoginScreenSucceededAction = createAction(`${ACTION_TYPE} Inititalizing Login Screen succeeded`, props<{ os: string }>());
